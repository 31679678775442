//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DataTable from "@/components/DataTable/DataTable";
export default {
  components: {
    DataTable
  },
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    reportSettings: {
      type: Object,
      required: true
    },
    type: {
      type: Number
    }
  },
  methods: {
    async tableLoader({
      query
    }) {
      if (query.action === "export") {
        query.page_size = 100000;
      }
      let result = {};
      if (this.type === 1) {
        result = await this.$store.dispatch("seo/getSearchQueriesByCategory", {
          ...this.reportSettings,
          ...query
        });
      }
      if (this.type === 2) {
        result = await this.$store.dispatch("seo/getSearchQueriesBySku", {
          ...this.reportSettings,
          ...query
        });
      }
      if (this.type === 3) {
        result = await this.$store.dispatch("seo/getSearchQueriesByQueries", {
          ...this.reportSettings,
          ...query
        });
      }
      if (query.action === "export") {
        console.log(query);
        let data = result.data;
        const titleNames = this.columns.map(columnRaw => {
          const {
            name,
            title
          } = columnRaw;
          return {
            name,
            title
          };
        });
        let option = {};
        const dataset = data.map(el => {
          let object = {};
          titleNames.forEach(item => {
            object[item.name] = el[item.name];
          });
          return object;
        });
        option.type = "excel";
        option.datas = [{
          sheetData: dataset,
          sheetName: "Page 1",
          sheetFilter: Object.keys(dataset[0]),
          sheetHeader: titleNames.map(item => item.title)
        }];
        return option;
      } else {
        return {
          items: result.data,
          total: result.total
        };
      }
    }
  },
  computed: {
    columns() {
      return [{
        title: "要求",
        show: true,
        name: "keyword",
        type: "text",
        width: 200,
        filter: "text"
      }, {
        title: `频率 ${this.reportSettings.datasource.toUpperCase()}`,
        show: true,
        name: "shows",
        type: "number",
        width: 200,
        filter: "numberRange"
      }, this.type === 3 && {
        title: "类型",
        show: true,
        name: "type",
        type: "slot",
        width: 200
      }, {
        title: "根据要求提供结果",
        show: true,
        name: "tot_keyword_products",
        type: "number",
        width: 200,
        filter: "numberRange"
      }];
    }
  }
};